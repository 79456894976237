import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "./pages/HomePage";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import CreatePage from "./pages/CreatePage";
import ArticlePage from "./pages/ArticlePage";
import NotFoundPage from "./pages/NotFoundPage";
import PrivateRouteComponent from "./common/PrivateRouteComponent";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import UpdatePage from "./pages/UpdatePage";

function SwitchComponent() {
    const Article = ({ match }) => {
        return <ArticlePage articleId={match.params.id} />;
    };

    const Update = ({ match }) => {
        return <UpdatePage articleId={match.params.id} />;
    };

    return (
        <Switch>
            <Route path="/home" component={() => <HomePage />} />
            <Route path="/login" component={() => <LoginPage />} />
            <Route
                path="/index/science"
                component={() => <IndexPage type="science" />}
            />
            <Route
                path="/index/history"
                component={() => <IndexPage type="history" />}
            />
            <Route
                path="/index/books"
                component={() => <IndexPage type="books" />}
            />
            <Route
                path="/index/movies"
                component={() => <IndexPage type="movies" />}
            />
            <Route
                path="/index/travelling"
                component={() => <IndexPage type="travelling" />}
            />
            <Route
                path="/index/general"
                component={() => <IndexPage type="general" />}
            />
            <Route path="/index/tv" component={() => <IndexPage type="tv" />} />
            <Route path="/article/:id" component={Article} />
            <Route path="/404-not-found" component={() => <NotFoundPage />} />
            <Route
                path="/401-unauthorized"
                component={() => <UnauthorizedPage />}
            />
            <PrivateRouteComponent
                path="/create-new"
                component={() => <CreatePage />}
            />
            <PrivateRouteComponent path="/update-post/:id" component={Update} />
            <PrivateRouteComponent
                path="/index/personal"
                component={() => <IndexPage type="personal" />}
            />
            <Redirect to="/home" />
        </Switch>
    );
}

export default SwitchComponent;
