import React from "react";
import { Button } from "reactstrap";
import { PublishPostRequest } from "../../common/Requests";
import { useAuthContext } from "../../context/AuthContext";

function PublishButtonComponent(props) {
    const { hasAccess } = useAuthContext();
    const handlePublishPost = () => {
        PublishPostRequest(props.post.id, (res) => {
            if (res.success) {
                window.location.reload();
            } else {
                console.log(res);
            }
        });
    };

    if (hasAccess) {
        return (
            <Button color="success" onClick={handlePublishPost}>
                {props.post.isPublished ? "Remove" : "Publish"}
            </Button>
        );
    } else {
        return <div></div>;
    }
}

export default PublishButtonComponent;
