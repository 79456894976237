import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

function PrivateRouteComponent({ component: Component, ...rest }) {
    const { hasAccess } = useAuthContext();
    return (
        <Route
            {...rest}
            render={(props) =>
                hasAccess ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/401-unauthorized" />
                )
            }
        />
    );
}

export default PrivateRouteComponent;
