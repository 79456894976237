import React from "react";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "reactstrap";

function UpdateButtonComponent(props) {
    const { hasAccess } = useAuthContext();
    const handleButtonClick = () => {
        window.location.href = "/update-post/" + props.post.id;
    };
    if (hasAccess) {
        return (
            <Button onClick={handleButtonClick} color="warning">
                Update
            </Button>
        );
    } else {
        return <div></div>;
    }
}

export default UpdateButtonComponent;
