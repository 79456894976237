import React, { useState, useEffect } from "react";
import { SinglePostRequest, VisitedPostRequest } from "../../common/Requests";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { API } from "../../common/BaseUrl";
import UpdateButtonComponent from "../admin/UpdateButtonComponent";
import DeleteButtonComponent from "../admin/DeleteButtonComponent";
import { useAuthContext } from "../../context/AuthContext";
import PublishButtonComponent from "../admin/PublishButtonComponent";
import RenderComments from "./RenderComments";
import CommentComponent from "../common/CommentComponent";
import { TimeComponent } from "../common/TimeComponent";
import TypeComponent from "../common/TypeComponent";

function ArticlePage(props) {
    const { hasAccess } = useAuthContext();
    const [articleId] = useState(props.articleId);
    const [isLoading, setIsLoading] = useState(true);
    const [post, setPost] = useState({ isError: false, errMsg: "", data: [] });

    const handleFetchData = () => {
        SinglePostRequest(articleId, (res) => {
            setIsLoading(false);
            if (res.data.success) {
                if (!res.data.data.isPublished) {
                    if (!hasAccess) {
                        var error = "";
                        throw error;
                    }
                }
                setPost({
                    ...post,
                    data: res.data.data,
                });
            } else {
                setPost({
                    ...post,
                    isError: true,
                    errMsg: res.data.message,
                });
            }
            setIsLoading(false);
        }).catch((error) => {
            setPost({
                ...post,
                isError: true,
                errMsg: "Your Requested Post Can not be Found",
            });
        });
    };

    const handleVisitorCount = () => {
        if (!hasAccess) {
            VisitedPostRequest(articleId, (res) => {
                if (res.success) {
                    setPost({
                        ...post,
                        data: res.data,
                    });
                }
            });
        }
    };

    useEffect(() => {
        handleFetchData();
        handleVisitorCount();
    }, [articleId]);

    if (isLoading) {
        return <LoadingComponent />;
    } else if (post.isError) {
        return <ErrorComponent error={post.errMsg} />;
    } else if (post.data) {
        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-6 single-article-img">
                        <img
                            className="article-img"
                            src={API.IMAGE + post.data.image}
                            alt={post.data.title}
                        />
                    </div>
                    <div className="col-12 col-lg-6 single-article-title">
                        <h5>{post.data.title}</h5>
                        <h6>
                            <TypeComponent type={post.data.type} />
                        </h6>
                        <label>
                            <b>Published:</b>{" "}
                            <TimeComponent time={post.data.published_at} />
                        </label>
                        <br />
                        <label>
                            <h5>Visited {post.data.visitors} Times</h5>
                        </label>
                        <div className="row">
                            <div className="col-4">
                                <UpdateButtonComponent post={post.data} />
                            </div>
                            <div className="col-4">
                                <PublishButtonComponent post={post.data} />
                            </div>
                            <div className="col-4">
                                <DeleteButtonComponent post={post.data} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 single-article-content">
                        {post.data.content}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <CommentComponent id={articleId} />
                    </div>
                </div>
                <br />
                <div>
                    <RenderComments id={articleId} />
                </div>
            </>
        );
    }
}

export default ArticlePage;
