export const TYPES = [
    {
        id: 0,
        name: "general",
        display: "General Topics",
        icon: "fa fa-cloud fa-lg",
    },
    {
        id: 1,
        name: "science",
        display: "Science & Technology",
        icon: "fa fa-microchip fa-lg",
    },
    {
        id: 2,
        name: "history",
        display: "History",
        icon: "fa fa-history fa-lg",
    },
    {
        id: 4,
        name: "books",
        display: "Books",
        icon: "fa fa-book fa-lg",
    },
    {
        id: 5,
        name: "movies",
        display: "Movies",
        icon: "fa fa-ticket fa-lg",
    },
    {
        id: 6,
        name: "tv",
        display: "TV Shows",
        icon: "fa fa-television fa-lg",
    },
    {
        id: 7,
        name: "travelling",
        display: "Travelling",
        icon: "fa fa-plane fa-lg",
    },
    {
        id: 8,
        name: "personal",
        display: "Personal Log",
        icon: "fa fa-user fa-lg",
    },
];
