import React from "react";
import { TYPES } from "../../common/ArticleTypes";

function TypeComponent(props) {
    const content = TYPES.map((type) => {
        if (type.name === props.type) {
            return <>{type.display}</>;
        } else {
            return <></>;
        }
    });

    return <div>{content}</div>;
}

export default TypeComponent;
