import React, { useState, useEffect } from "react";

function ScrollComponent() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const toggleVisibility = () => {
        if (window.pageYOffset > 200) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", () => {
            toggleVisibility();
        });
    }, []);
    return (
        <div
            onClick={scrollToTop}
            className={`scroll-to-top ${isVisible ? "" : "scroll-invisible"}`}
        >
            <i className="fa fa-arrow-circle-up fa-lg scroll-to-top-inner"></i>
        </div>
    );
}

export default ScrollComponent;
