import React, { useState } from "react";
import {
    Nav,
    Navbar,
    NavItem,
    NavLink,
    NavbarToggler,
    Collapse,
} from "reactstrap";
import { TYPES } from "../../common/ArticleTypes";

function LeftNavComponent() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <section className="sidebar">
            <Navbar color="faded" expand="md">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="nav-left" vertical navbar>
                        <NavItem className="nav-item-left">
                            <NavLink href="/home" className="btn nav-link-left">
                                <span className="fa fa-globe fa-bg"></span> Home
                            </NavLink>
                        </NavItem>
                        <RenderArticleTypes />
                    </Nav>
                </Collapse>
            </Navbar>
        </section>
    );
}

export default LeftNavComponent;

function RenderArticleTypes() {
    const types = TYPES.map((type) => {
        return (
            <NavItem key={type.id} className="nav-item-left">
                <NavLink
                    href={"/index/" + type.name}
                    className="btn nav-link-left"
                >
                    <span className={type.icon}></span> {type.display}
                </NavLink>
            </NavItem>
        );
    });

    return <>{types}</>;
}
