import React from "react";
import { Button } from "reactstrap";
import { DeletePostRequest } from "../../common/Requests";
import { useAuthContext } from "../../context/AuthContext";

function DeleteButtonComponent(props) {
    const { hasAccess } = useAuthContext();
    if (hasAccess) {
        const handleDelete = () => {
            DeletePostRequest(props.post.id).then((res) => {
                if (res.success) {
                    window.location.href = "/home";
                } else {
                    console.log(res);
                }
            });
        };

        return (
            <Button color="danger" onClick={handleDelete}>
                Delete
            </Button>
        );
    } else {
        return <div></div>;
    }
}

export default DeleteButtonComponent;
