import React, { useState, useEffect, useRef } from "react";
import LoadingComponent from "../common/LoadingComponent";
import { TyposPostsRequest } from "../../common/Requests";
import ErrorComponent from "../common/ErrorComponent";
import RenderPosts from "./RenderPosts";
import { Pagination } from "react-laravel-paginex";
import TypeComponent from "../common/TypeComponent";

function IndexPage(props) {
    let initMount = useRef(true);
    const [type] = useState(props.type);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState({ isError: false, errMsg: "" });
    const [posts, setPosts] = useState({ posts: [], pagination: [] });
    const [pagination, setPagination] = useState();

    const handleDataFetching = (page) => {
        TyposPostsRequest(type, page, (res) => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            setIsLoading(true);
            if (res.data.success) {
                setPosts({
                    ...posts,
                    posts: res.data.data.data,
                    pagination: res.data.data,
                });
                setPagination(res.data.data);
            } else {
                console.log(res);
                setError({
                    isError: true,
                    errMsg: "Data Fetching Failed",
                });
            }
            setIsLoading(false);
        });
    };

    function getPaginationData(data) {
        handleDataFetching(data.page);
    }

    useEffect(() => {
        if (initMount) {
            initMount.current = false;
            handleDataFetching(1);
        }
    }, []);

    if (isLoading) {
        return <LoadingComponent />;
    } else if (error.isError) {
        return <ErrorComponent error={error.errMsg} />;
    } else {
        return (
            <div>
                <h4>
                    <TypeComponent type={type} />{" "}
                </h4>
                <hr />
                <RenderPosts items={posts.posts} />
                <Pagination changePage={getPaginationData} data={pagination} />
            </div>
        );
    }
}

export default IndexPage;
