import React, { useState, useEffect } from "react";
import { SinglePostRequest, UpdatePostRequest } from "../../common/Requests";
import { Form, FormGroup, FormText, Button, Input, Label } from "reactstrap";
import OptionsComponent from "../admin/OptionsComponent";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { API } from "../../common/BaseUrl";

function UpdatePage(props) {
    var initialMount = true;
    const [articleId] = useState(props.articleId);
    const [status, setStatus] = useState({
        isLoading: true,
        isError: false,
        errMsg: "",
    });
    const [post, setPost] = useState({
        id: "",
        title: "",
        content: "",
        image: "",
        file: "",
        tags: "",
        isFeatured: "",
        type: "select",
    });

    const handleFetchData = () => {
        SinglePostRequest(articleId, (res) => {
            if (res.data.success) {
                const updatePost = res.data.data;
                setPost({
                    ...post,
                    id: updatePost.id,
                    title: updatePost.title,
                    content: updatePost.content,
                    file: API.IMAGE + updatePost.image,
                    tags: updatePost.tags,
                    type: updatePost.type,
                    isFeatured: updatePost.isFeatured,
                });
                setStatus({
                    ...status,
                    isLoading: false,
                    isError: false,
                    errMsg: "",
                });
            } else {
                setStatus({
                    ...status,
                    isLoading: false,
                    isError: true,
                    errMsg: res.data.message,
                });
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            id: articleId,
            title: post.title,
            content: post.content,
            tags: post.tags,
            type: post.type,
            isFeatured: post.isFeatured,
            image: post.image,
        };

        // const data = new FormData();
        // data.append("id", articleId);
        // data.append("title", post.title);
        // data.append("content", post.content);
        // data.append("image", post.image);
        // data.append("tags", post.tags);
        // data.append("type", post.type);

        UpdatePostRequest(articleId, data, (res) => {
            if (res.success) {
                window.location.href = "/article/" + post.id;
            }
        });
    };

    useEffect(() => {
        if (initialMount) {
            initialMount = false;
            handleFetchData();
        }
    }, []);

    if (status.isLoading) {
        return <LoadingComponent />;
    } else if (status.isError) {
        return <ErrorComponent error={status.errMsg} />;
    }
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 col-lg-6 single-article-img">
                        <img
                            className="article-img"
                            src={post.file}
                            alt="Star Trek"
                        />
                        <FormGroup>
                            <Input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        file: URL.createObjectURL(
                                            e.target.files[0]
                                        ),
                                        image: e.target.files[0],
                                    });
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12 col-lg-6 single-article-title">
                        <FormGroup>
                            <Label htmlFor="title">Title</Label>
                            <Input
                                type="text"
                                id="title"
                                name="title"
                                value={post.title}
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="tags">Tags</Label>
                            <Input
                                type="tags"
                                id="tags"
                                name="tags"
                                value={post.tags}
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        tags: e.target.value,
                                    });
                                }}
                            />
                            <FormText>Seperate Using Space</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="type">Type</Label>
                            <Input
                                type="select"
                                id="type"
                                name="type"
                                value={post.type}
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        type: e.target.value,
                                    });
                                }}
                            >
                                <option value="select" disabled>
                                    (select)
                                </option>
                                <OptionsComponent />
                            </Input>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id="isFeatured"
                                name="isFeatured"
                                checked={post.isFeatured}
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        isFeatured: e.target.checked,
                                    });
                                }}
                            />
                            <Label htmlFor="isFeatured">
                                Make This Featured Article
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 single-article-content">
                        <FormGroup>
                            <Label htmlFor="content">Content</Label>
                            <Input
                                type="textarea"
                                id="content"
                                name="content"
                                rows="12"
                                value={post.content}
                                onChange={(e) => {
                                    setPost({
                                        ...post,
                                        content: e.target.value,
                                    });
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>
                <Button className="btn-update" type="submit" size="sm">
                    Submit
                </Button>
            </Form>
            <br />
        </>
    );
}

export default UpdatePage;
