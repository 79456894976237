import React from "react";
import { Spinner } from "reactstrap";
function LoadingComponent() {
    return (
        <div className="spinner">
            <Spinner animation="border" variant="success" />
        </div>
    );
}

export default LoadingComponent;
