import React, { useState } from "react";
import {
    Button,
    Nav,
    Navbar,
    NavItem,
    NavLink,
    Collapse,
    NavbarToggler,
    NavbarBrand,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { useAuthContext } from "../../context/AuthContext";
import { SessionKeys } from "../../common/StorageKeys";

function HeaderComponent() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="em-header">
            <Navbar dark expand="sm">
                <NavbarBrand href="/home">BLOG</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItems />
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

function NavItems() {
    const { hasAccess } = useAuthContext();
    const { setHasAccess } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleLogOut = (e) => {
        e.preventDefault();
        sessionStorage.removeItem(SessionKeys.TOKEN);
        sessionStorage.removeItem(SessionKeys.ACCESS);
        sessionStorage.removeItem(SessionKeys.USER);
        setHasAccess(false);
    };

    if (hasAccess) {
        return (
            <>
                <Modal isOpen={isOpen} size="sm">
                    <ModalBody>Are You Sure?</ModalBody>
                    <ModalFooter>
                        <Button size="sm" color="success" onClick={toggle}>
                            {" "}
                            Cancel
                        </Button>
                        <Button size="sm" color="danger" onClick={handleLogOut}>
                            SignOut
                        </Button>
                    </ModalFooter>
                </Modal>
                <NavItem>
                    <NavLink className="btn" href="/create-new">
                        <span className="fa fa-plus-circle fa-lg"></span> New
                        Article
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className="btn" onClick={toggle}>
                        <span className="fa fa-sign-out fa-lg"></span> Logout
                    </NavLink>
                </NavItem>
            </>
        );
    } else {
        return (
            <NavItem>
                <NavLink href="/login">
                    <span className="fa fa-sign-in fa-lg"></span> Login
                </NavLink>
            </NavItem>
        );
    }
}

export default HeaderComponent;
