import React from "react";
import { withRouter } from "react-router-dom";
import HeaderComponent from "./common/HeaderComponent";
import FavouriteComponent from "./common/FavouriteComponent";
import LeftNavComponent from "./common/LeftNavComponent";
import FooterComponent from "./common/FooterComponent";
import ScrollComponent from "./common/ScrollComponent";
import SwitchComponent from "./SwitchComponent";

function MainComponent() {
    return (
        <div>
            <HeaderComponent />
            <FavouriteComponent />
            <div className="main-body">
                <div className="left-panel">
                    <LeftNavComponent />
                </div>
                <div className="container-fluid right-panel">
                    <div className="right-panel-inner">
                        <SwitchComponent />
                    </div>
                </div>
            </div>
            <ScrollComponent />
            <FooterComponent />
        </div>
    );
}

export default withRouter(MainComponent);
