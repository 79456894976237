import React from "react";
import { TYPES } from "../../common/ArticleTypes";

function OptionsComponent() {
    const options = TYPES.map((type) => {
        return (
            <option key={type.id} value={type.name}>
                {type.display}
            </option>
        );
    });

    return <>{options}</>;
}

export default OptionsComponent;
