import React from "react";

function FooterComponent() {
    return (
        <div className="em-footer">
            Personal Blog of Md. Sazid Hossain Emon
            <br />
            Email: self@mdsazidhossain.com
        </div>
    );
}

export default FooterComponent;
