import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import { useAuthContext } from "../../context/AuthContext";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import {
    CommentsRequest,
    CommentApproveRequest,
    CommentDeleteRequest,
} from "../../common/Requests";
import { TimeComponent } from "../common/TimeComponent";

function RenderComments(props) {
    const [id] = useState(props.id);
    const [comments, setComments] = useState({
        isLoading: true,
        isError: false,
        errMsg: "",
        data: [],
    });

    const handleFetchData = () => {
        CommentsRequest(id, (res) => {
            if (res.success) {
                setComments({
                    ...comments,
                    isLoading: false,
                    isError: false,
                    errMsg: "",
                    data: res.data,
                });
            } else {
                setComments({
                    ...comments,
                    isLoading: false,
                    isError: true,
                    errMsg: "Something Went Wrong",
                    data: [],
                });
            }
        });
    };

    useEffect(() => {
        handleFetchData();
        return () => {
            console.log("Return Called");
        };
    }, [id]);

    if (comments.isLoading) {
        return <LoadingComponent />;
    } else if (comments.isError) {
        return <ErrorComponent error={comments.errMsg} />;
    } else {
        return (
            <div>
                <CommentsComp comments={comments.data} />
            </div>
        );
    }
}

function CommentsComp(props) {
    const { hasAccess } = useAuthContext();

    const handleApproveSubmit = (e) => {
        CommentApproveRequest(e.target.id, (res) => {
            if (res.success) {
                window.location.reload();
            } else {
                console.log(res);
            }
        });
    };

    const handleDeleteSubmit = (e) => {
        CommentDeleteRequest(e.target.id, (res) => {
            if (res.success) {
                window.location.reload();
            } else {
                console.log(res);
            }
        });
    };

    if (props.comments.length === 0) {
        return <div></div>;
    } else {
        const comments = props.comments.map((comment) => {
            if (hasAccess) {
                return (
                    <div className="col-12 col-lg-6" key={comment.id}>
                        <Card className="card-comment">
                            <CardBody className="card-body-comment">
                                <h5>{comment.author}</h5>
                                <label>
                                    <TimeComponent time={comment.created_at} />{" "}
                                </label>
                                <p>{comment.comment}</p>
                            </CardBody>
                            <CardFooter className="card-footer-comment">
                                <Button
                                    className={`${
                                        comment.isApproved
                                            ? "btn-comment btn-comment-disapprove"
                                            : "btn-comment btn-comment-approve"
                                    }`}
                                    onClick={handleApproveSubmit}
                                    id={comment.id}
                                >
                                    {comment.isApproved
                                        ? "Disapprove"
                                        : "Approve"}
                                </Button>
                                &nbsp;
                                <Button
                                    className="btn-comment btn-comment-remove"
                                    onClick={handleDeleteSubmit}
                                    id={comment.id}
                                >
                                    <span className="fa fa-trash fa-lg"></span>
                                </Button>
                            </CardFooter>
                        </Card>
                    </div>
                );
            } else {
                if (comment.isApproved) {
                    return (
                        <div className="col-12 col-lg-6" key={comment.id}>
                            <Card className="card-comment">
                                <CardBody className="card-body-comment">
                                    <h5>{comment.author}</h5>
                                    <label>
                                        <TimeComponent
                                            time={comment.created_at}
                                        />{" "}
                                    </label>
                                    <p>{comment.comment}</p>
                                </CardBody>
                            </Card>
                        </div>
                    );
                } else {
                    return <div key={comment.id}></div>;
                }
            }
        });
        return (
            <>
                <h5>Comments</h5>
                <div className="row">{comments}</div>
            </>
        );
    }
}

export default RenderComments;
