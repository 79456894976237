import React from "react";
import { Link } from "react-router-dom";

function ReadMoreComponent(props) {
    if (props.post.content.length >= 250) {
        const handleOnClick = () => {
            props.onClick();
        };

        return (
            <div>
                <div>
                    {props.post.content.substring(0, 120) + "..."}
                    <Link to={"/article/" + props.post.id}>Read More</Link>
                </div>
            </div>
        );
    } else {
        return <div>{props.post.content}</div>;
    }
}

export default ReadMoreComponent;
