export const LocalKeys = {
    TOKEN: "_token",
    USER: "user",
    ACCESS: "access",
};

export const SessionKeys = {
    TOKEN: "_token",
    USER: "user",
    ACCESS: "access",
};
