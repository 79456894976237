import React, { useState } from "react";
import { Input, Form, FormGroup, Label, Button, FormText } from "reactstrap";
import OptionsComponent from "../admin/OptionsComponent";
import { CreatePostRequest } from "../../common/Requests";

function CreatePage() {
    const [newPost, setNewPost] = useState({
        title: "",
        content: "",
        image: null,
        file: "/img/upload.png",
        tags: "",
        type: "select",
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        var data = new FormData();
        data.append("type", newPost.type);
        data.append("title", newPost.title);
        data.append("content", newPost.content);
        data.append("image", newPost.image);
        data.append("tags", newPost.tags);

        CreatePostRequest(data, (res) => {
            if (res.success) {
                window.location.href = "/update-post/" + res.data.id;
            } else {
                alert(res.data);
            }
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 col-lg-6 single-article-img">
                        <img
                            className="article-img"
                            src={newPost.file}
                            alt="Star Trek"
                        />
                        <FormGroup>
                            <Input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        file: URL.createObjectURL(
                                            e.target.files[0]
                                        ),
                                        image: e.target.files[0],
                                    });
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12 col-lg-6 single-article-title">
                        <FormGroup>
                            <Label htmlFor="title">Title</Label>
                            <Input
                                type="text"
                                id="title"
                                name="title"
                                value={newPost.title}
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        title: e.target.value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="tags">Tags</Label>
                            <Input
                                type="tags"
                                id="tags"
                                name="tags"
                                value={newPost.tags}
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        tags: e.target.value,
                                    });
                                }}
                            />
                            <FormText>Seperate Using Space</FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="type">Type</Label>
                            <Input
                                type="select"
                                id="type"
                                name="type"
                                value={newPost.type}
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        type: e.target.value,
                                    });
                                }}
                            >
                                <option value="select" disabled>
                                    (select)
                                </option>
                                <OptionsComponent />
                            </Input>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                id="isFeatured"
                                name="isFeatured"
                                checked={newPost.isFeatured}
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        isFeatured: e.target.checked,
                                    });
                                }}
                            />
                            <Label htmlFor="isFeatured">
                                Make This Featured Article
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 single-article-content">
                        <FormGroup>
                            <Label htmlFor="content">Content</Label>
                            <Input
                                type="textarea"
                                id="content"
                                name="content"
                                rows="12"
                                value={newPost.content}
                                onChange={(e) => {
                                    setNewPost({
                                        ...newPost,
                                        content: e.target.value,
                                    });
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>
                <Button className="btn-update" type="submit" size="sm">
                    Submit
                </Button>
            </Form>
            <br />
        </>
    );
}

export default CreatePage;
