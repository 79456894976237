import React from "react";
import { Link } from "react-router-dom";
import { API } from "../../common/BaseUrl";
import ReadMoreComponent from "../common/ReadMoreComponent";
import { TimeComponent } from "../common/TimeComponent";

function RenderPosts(props) {
    const items = props.items.map((post) => {
        return (
            <div key={post.id} className="row item-card">
                <div className="col-lg-4 item-card-img">
                    <Link to={"/article/" + post.id}>
                        <img
                            src={API.IMAGE + post.image}
                            alt="Star Trek Alternate Reality"
                        />
                    </Link>
                </div>
                <div className="col-lg-8 item-card-body">
                    <h5>{post.title}</h5>
                    <label>
                        Published: <TimeComponent time={post.published_at} />{" "}
                    </label>
                    <br />
                    <label>Tags: Star Trek Discovery</label>
                    <ReadMoreComponent post={post} />
                </div>
            </div>
        );
    });

    return <div>{items}</div>;
}

export default RenderPosts;
