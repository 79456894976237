import React from "react";

export function TimeComponent(props) {
    var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };
    var time = new Date(props.time).toLocaleDateString([], options);

    return <label className="date-time">{time}</label>;
}
