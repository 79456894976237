import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    FormFeedback,
} from "reactstrap";
import { CreateCommentRequest } from "../../common/Requests";

function CommentComponent(props) {
    const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [postId] = useState(props.id);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [author, setAuthor] = useState({
        touched: false,
        isValid: false,
        errMsg: "Author Name is Required",
        data: "",
    });
    const [email, setEmail] = useState({
        touched: false,
        isValid: true,
        errMsg: "",
        data: "",
    });
    const [comment, setComment] = useState({
        touched: false,
        isValid: false,
        errMsg: "Comment is Required",
        data: "",
    });

    const toggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const checkAuthorValidation = () => {
        if (author.data === "") {
            setAuthor({
                ...author,
                isValid: false,
                errMsg: "Author Name is Required",
            });
        } else {
            setAuthor({
                ...author,
                isValid: true,
                errMsg: "",
            });
        }
    };

    const checkEmailValidation = () => {
        if (email.touched) {
            if (email.data === "" || EmailRegex.test(email.data)) {
                setEmail({
                    ...email,
                    isValid: true,
                });
            } else {
                setEmail({
                    ...email,
                    isValid: false,
                    errMsg: "Invalid Email Format",
                });
            }
        }
    };

    const checkCommentValidation = () => {
        if (comment.data === "") {
            setComment({
                ...comment,
                isValid: false,
                errMsg: "Comment is Required",
            });
        } else {
            setComment({
                ...comment,
                isValid: true,
            });
        }
    };

    const handleCommentSubmit = (e) => {
        e.preventDefault();

        if (author.isValid && email.isValid && comment.isValid) {
            const data = new FormData();

            data.append("post_id", postId);
            data.append("author", author.data);
            data.append("email", email.data);
            data.append("comment", comment.data);

            CreateCommentRequest(data, (res) => {
                if (res.success) {
                    alert("Your comment has been Submitted for Review");
                } else {
                    alert("Something Went Wrong. Please Try Again");
                }
                toggle();
            });
        } else {
            document.getElementById("author").focus();
        }
    };

    useEffect(() => {
        checkAuthorValidation();
        checkEmailValidation();
        checkCommentValidation();
    }, [author.data, email.data, comment.data]);
    return (
        <>
            <Modal isOpen={isModalOpen} className="modal-comment">
                <ModalHeader className="modal-header-comment">
                    ADD YOUR COMMENT
                </ModalHeader>
                <ModalBody className="modal-body-comment">
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col sm="4">Name</Col>
                                <Col sm="8">
                                    <Input
                                        type="text"
                                        id="author"
                                        name="author"
                                        value={author.data}
                                        onChange={(e) => {
                                            setAuthor({
                                                ...author,
                                                data: e.target.value,
                                            });
                                        }}
                                        className={
                                            author.isValid
                                                ? "is-valid"
                                                : "is-invalid"
                                        }
                                    />
                                    <FormFeedback>{author.errMsg}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm="4">Email</Col>
                                <Col sm="8">
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email.data}
                                        onChange={(e) => {
                                            setEmail({
                                                ...email,
                                                touched: true,
                                                data: e.target.value,
                                            });
                                        }}
                                        className={
                                            email.isValid
                                                ? "is-valid"
                                                : "is-invalid"
                                        }
                                    />
                                    <FormFeedback>{email.errMsg}</FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm="4">Comment</Col>
                                <Col sm="8">
                                    <Input
                                        type="textarea"
                                        id="comment"
                                        name="comment"
                                        rows="4"
                                        value={comment.data}
                                        onChange={(e) => {
                                            setComment({
                                                ...comment,
                                                touched: true,
                                                data: e.target.value,
                                            });
                                        }}
                                        className={
                                            comment.isValid
                                                ? "is-valid"
                                                : "is-invalid"
                                        }
                                        required
                                    />
                                    <FormFeedback>
                                        {comment.errMsg}
                                    </FormFeedback>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className="modal-footer-comment">
                    <Button
                        size="sm"
                        className="btn-modal-comment-dismiss btn-comment"
                        onClick={toggle}
                    >
                        Dismiss
                    </Button>
                    <Button
                        size="sm"
                        className="btn-modal-comment-submit btn-comment"
                        onClick={handleCommentSubmit}
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
            <Button
                className="btn-add-comment btn-comment float-right"
                onClick={toggle}
            >
                ADD COMMENT
            </Button>
        </>
    );
}

export default CommentComponent;
