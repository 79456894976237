import React from "react";

function ErrorComponent(props) {
    return (
        <div className="loading-error">
            <h5>{props.error}</h5>
        </div>
    );
}

export default ErrorComponent;
