import React from "react";

function UnauthorizedPage() {
    return (
        <div className="not-found">
            <h5>SORRY, YOU ARE NOT AUTHORIZED TO ACCESS THIS PAGE</h5>
        </div>
    );
}

export default UnauthorizedPage;
