export const BaseUrl = "https://api.blog.mdsazidhossain.com/api/";
export const TestUrl = "https://jsonplaceholder.typicode.com/";

export const API = {
    LOGIN: BaseUrl + "login",
    IMAGE: BaseUrl + "s3/",

    POSTS: BaseUrl + "posts",
    FEATURED: BaseUrl + "posts/featured-post",
    MOSTVISITED: BaseUrl + "posts/most-visited",
    POST: BaseUrl + "posts/show/",
    POSTTYPOS: BaseUrl + "typos/",
    POSTCREATE: BaseUrl + "posts",
    POSTUPDATE: BaseUrl + "posts/update/",
    POSTDELETE: BaseUrl + "posts/delete/",
    POSTPUBLISH: BaseUrl + "posts/publish/",
    POSTVISITED: BaseUrl + "posts/visited/",

    COMMENTS: BaseUrl + "comments/",
    COMMENTCREATE: BaseUrl + "comments",
    COMMENTAPPROVE: BaseUrl + "comments/approve/",
    COMMENTDELETE: BaseUrl + "comments/delete/",
};

export const TESTAPI = {
    POSTS: TestUrl + "posts",
};
