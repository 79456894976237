import React, { useState, useEffect } from "react";
import {
    Form,
    Button,
    Label,
    Input,
    FormGroup,
    FormFeedback,
} from "reactstrap";
import { LoginRequest } from "../../common/Requests";
import { SessionKeys } from "../../common/StorageKeys";
import { useAuthContext } from "../../context/AuthContext";

function LoginPage() {
    const { hasAccess, setHasAccess } = useAuthContext();
    if (hasAccess) {
        window.location.href = "/home";
    }

    const [emailParam, setEmailParam] = useState({
        touched: false,
        email: "",
        isValid: true,
        errMsg: "",
    });
    const [passParam, setPassParam] = useState({
        touched: false,
        password: "",
        isValid: true,
        errMsg: "",
    });
    const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const checkEmailValidation = () => {
        if (emailParam.touched) {
            if (emailParam.email === "") {
                setEmailParam({
                    ...emailParam,
                    isValid: false,
                    errMsg: "Email field required",
                });
            } else if (EmailRegex.test(emailParam.email)) {
                setEmailParam({
                    ...emailParam,
                    isValid: true,
                    errMsg: "",
                });
            } else {
                setEmailParam({
                    ...emailParam,
                    isValid: false,
                    errMsg: "Invalid Email Format",
                });
            }
        }
    };

    const checkPassValidation = () => {
        if (passParam.touched) {
            if (passParam.password.length === 0) {
                setPassParam({
                    ...passParam,
                    isValid: false,
                    errMsg: "Password Must be 8 Digit Long",
                });
            } else if (passParam.password.length < 8) {
                setPassParam({
                    ...passParam,
                    isValid: false,
                    errMsg: "Password Must be 8 Digit Long",
                });
            } else {
                setPassParam({
                    ...passParam,
                    isValid: true,
                    errMsg: "",
                });
            }
        }
    };

    const handleLoginSubmit = (e) => {
        e.preventDefault();

        if (emailParam.email && emailParam.isValid) {
            if (passParam.password && passParam.isValid) {
                const data = {
                    email: emailParam.email,
                    password: passParam.password,
                };
                LoginRequest(data, (res) => {
                    if (res.success) {
                        sessionStorage.setItem(
                            SessionKeys.TOKEN,
                            res.data.token
                        );
                        sessionStorage.setItem(
                            SessionKeys.USER,
                            JSON.stringify(res.data.user)
                        );
                        sessionStorage.setItem(SessionKeys.ACCESS, true);
                        setHasAccess(true);
                        window.location.href = "/home";
                    } else {
                        setEmailParam({
                            ...emailParam,
                            isValid: false,
                            errMsg: "Email & Password Don't Match Our Records",
                        });
                        setPassParam({
                            ...passParam,
                            isValid: false,
                            errMsg: "Email & Password Don't Match Our Records",
                        });
                    }
                });
            } else {
                document.getElementById("password").focus();
            }
        } else {
            document.getElementById("email").focus();
        }
    };

    useEffect(() => {
        checkEmailValidation();
        checkPassValidation();
    }, [emailParam.email, passParam.password]);

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
                <Form onSubmit={handleLoginSubmit}>
                    <FormGroup>
                        <Label htmlFor="email">Email Address</Label>
                        <Input
                            type="email"
                            id="email"
                            name="email"
                            value={emailParam.email}
                            className={
                                emailParam.touched
                                    ? emailParam.isValid
                                        ? "is-valid"
                                        : "is-invalid"
                                    : ""
                            }
                            onChange={(e) => {
                                setEmailParam({
                                    ...emailParam,
                                    touched: true,
                                    email: e.target.value,
                                });
                            }}
                            autoComplete="off"
                        />
                        <FormFeedback>{emailParam.errMsg}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            value={passParam.password}
                            className={
                                passParam.touched
                                    ? passParam.isValid
                                        ? "is-valid"
                                        : "is-invalid"
                                    : ""
                            }
                            onChange={(e) => {
                                setPassParam({
                                    ...passParam,
                                    touched: true,
                                    password: e.target.value,
                                });
                            }}
                        />
                        <FormFeedback>{passParam.errMsg}</FormFeedback>
                    </FormGroup>
                    <Button type="submit" className="btn-featured">
                        Login
                    </Button>
                </Form>
            </div>
        </div>
    );
}

export default LoginPage;
