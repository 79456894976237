import React, { useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainComponent from "./components/MainComponent";
import { SessionKeys } from "./common/StorageKeys";
import { AuthContext } from "./context/AuthContext";

function App() {
    let access = sessionStorage.getItem(SessionKeys.ACCESS);
    if (access === null) {
        access = false;
    }
    const [hasAccess, setHasAccess] = useState(access);
    return (
        <AuthContext.Provider value={{ hasAccess, setHasAccess }}>
            <div className="App">
                <BrowserRouter>
                    <MainComponent />
                </BrowserRouter>
            </div>
        </AuthContext.Provider>
    );
}

export default App;
