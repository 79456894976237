import axios from "axios";
import { API } from "./BaseUrl";
import { SessionKeys } from "./StorageKeys";

export const LoginRequest = async (data, callback) => {
    await axios.post(API.LOGIN, data).then((res) => {
        callback(res.data);
    });
};

export const FeaturedPostRequest = async (callback) => {
    await axios.get(API.FEATURED).then((res) => {
        callback(res);
    });
};

export const MostVisitedPostRequest = async (callback) => {
    await axios.get(API.MOSTVISITED).then((res) => {
        callback(res);
    });
};

export const HomePostsRequest = async (page, callback) => {
    let url = API.POSTS + "?page=" + page;
    await axios.get(url).then((res) => {
        callback(res);
    });
};

export const TyposPostsRequest = async (type, page, callback) => {
    let url = API.POSTTYPOS + type + "?page=" + page;
    await axios.get(url).then((res) => {
        callback(res);
    });
};

export const SinglePostRequest = async (postId, callback) => {
    await axios
        .get(API.POST + postId)
        .then((res) => {
            callback(res);
        })
        .catch((error) => {
            callback(error);
        });
};

export const CreatePostRequest = async (data, callback) => {
    await axios({
        method: "POST",
        url: API.POSTCREATE,
        data: data,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};

export const UpdatePostRequest = async (id, data, callback) => {
    await axios({
        method: "PUT",
        url: API.POSTUPDATE + id,
        data: data,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};

export const PublishPostRequest = async (id, callback) => {
    await axios({
        method: "PUT",
        url: API.POSTPUBLISH + id,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};

export const VisitedPostRequest = async (id, callback) => {
    await axios({
        method: "PUT",
        url: API.POSTVISITED + id,
    }).then((res) => {
        callback(res.data);
    });
};

export const DeletePostRequest = async (id, callback) => {
    await axios({
        method: "DELETE",
        url: API.POSTDELETE + id,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};

export const CommentsRequest = async (id, callback) => {
    await axios.get(API.COMMENTS + id).then((res) => {
        callback(res.data);
    });
};

export const CreateCommentRequest = async (data, callback) => {
    await axios({
        method: "POST",
        url: API.COMMENTCREATE,
        data: data,
    })
        .then((res) => {
            callback(res.data);
        })
        .catch((res) => {
            callback(res);
        });
};

export const CommentApproveRequest = async (id, callback) => {
    await axios({
        method: "PUT",
        url: API.COMMENTAPPROVE + id,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};

export const CommentDeleteRequest = async (id, callback) => {
    await axios({
        method: "DELETE",
        url: API.COMMENTDELETE + id,
        headers: {
            Authorization:
                "Bearer " + sessionStorage.getItem(SessionKeys.TOKEN),
        },
    }).then((res) => {
        callback(res.data);
    });
};
