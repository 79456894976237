import React, { useState, useEffect } from "react";
import { Button, Collapse, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import {
    FeaturedPostRequest,
    MostVisitedPostRequest,
} from "../../common/Requests";
import { API } from "../../common/BaseUrl";
import LoadingComponent from "./LoadingComponent";
import ErrorComponent from "./ErrorComponent";
import ReadMoreComponent from "./ReadMoreComponent";

export default function FavouriteComponent() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="container-fluid featured-items">
            <div className="row">
                <div className="col-12">
                    <Button
                        className="float-right btn-featured"
                        onClick={toggle}
                    >
                        {isOpen ? "x" : "Featured Item"}
                    </Button>
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                <div className="row">
                    <div className="col-12 col-lg-6 featured-item">
                        <h5>Featured Article</h5>
                        <FeaturedItem onClick={toggle} />
                    </div>
                    <div className="col-12 col-lg-6 featured-item">
                        <h5>Most Viewed Article</h5>
                        <MostVisitedItem onClick={toggle} />
                    </div>
                </div>
            </Collapse>
            <hr />
        </div>
    );
}

function FeaturedItem(props) {
    let initialMount = true;
    const [featured, setFeatured] = useState({
        isLoading: true,
        isError: false,
        errMsg: "",
        post: [],
    });

    const onClickHandler = () => {
        props.onClick();
    };

    const handleDataFeatching = () => {
        FeaturedPostRequest((res) => {
            if (res.data.success) {
                setFeatured({
                    ...featured,
                    isLoading: false,
                    post: res.data.data,
                });
            } else {
                setFeatured({
                    ...featured,
                    isLoading: false,
                    isError: true,
                    errMsg: res.data.message,
                });
            }
        });
    };

    useEffect(() => {
        if (initialMount) {
            initialMount = false;
            handleDataFeatching();
        }
    }, []);

    if (featured.isLoading) {
        return <LoadingComponent />;
    } else if (featured.isError) {
        return <ErrorComponent error={featured.errMsg} />;
    } else {
        return (
            <Link
                onClick={onClickHandler}
                to={"/article/" + featured.post.id}
                className="fav-link"
            >
                <Card>
                    <CardBody>
                        <div className="row card-fav">
                            <div className="card-left">
                                <img
                                    className="fav-img"
                                    src={API.IMAGE + featured.post.image}
                                    alt="Star Trek"
                                />
                            </div>
                            <div className="card-right">
                                <h5>{featured.post.title}</h5>
                                <div>
                                    {featured.post.content.substring(0, 120) +
                                        "... (Read More)"}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Link>
        );
    }
}

function MostVisitedItem(props) {
    let initialMount = true;
    const [mostVisited, setMostVisited] = useState({
        isLoading: true,
        isError: false,
        errMsg: "",
        post: [],
    });

    const onClickHandler = () => {
        props.onClick();
    };

    const handleDataFeatching = () => {
        MostVisitedPostRequest((res) => {
            if (res.data.success) {
                setMostVisited({
                    ...mostVisited,
                    isLoading: false,
                    post: res.data.data,
                });
            } else {
                setMostVisited({
                    ...mostVisited,
                    isLoading: false,
                    isError: true,
                    errMsg: res.data.message,
                });
            }
        });
    };

    useEffect(() => {
        if (initialMount) {
            initialMount = false;
            handleDataFeatching();
        }
    }, []);

    if (mostVisited.isLoading) {
        return <LoadingComponent />;
    } else if (mostVisited.isError) {
        return <ErrorComponent error={mostVisited.errMsg} />;
    } else {
        return (
            <Link
                onClick={onClickHandler}
                to={"/article/" + mostVisited.post.id}
                className="fav-link"
            >
                <Card>
                    <CardBody>
                        <div className="row card-fav">
                            <div className="card-left">
                                <img
                                    className="fav-img"
                                    src={API.IMAGE + mostVisited.post.image}
                                    alt="Star Trek"
                                />
                            </div>
                            <div className="card-right">
                                <h5>{mostVisited.post.title}</h5>
                                <div>
                                    {mostVisited.post.content.substring(
                                        0,
                                        120
                                    ) + "... (Read More)"}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Link>
        );
    }
}
