import React from "react";

function NotFoundPage() {
    return (
        <div className="not-found">
            <h5>SORRY, WE CAN NOT FIND YOUR REQUESTED PAGE</h5>
        </div>
    );
}

export default NotFoundPage;
